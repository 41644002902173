.wrapper {
  position: relative;
  transition: box-shadow 0.3s;

  box-shadow:
    0px 0px 1.5px 0px #919e9b29,
    0px 3px 9px 0px #4c57540f,
    0px 5.25px 12.75px 0px #4c575414;

  &,
  .cover,
  .formats {
    border-radius: 6px;
  }

  .cover {
    width: 140px;
    height: 210px;
    display: block;
  }

  .formats {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;

    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    opacity: 0;
    transition: opacity 0.3s;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #c4ffeecc;

    gap: 6px;

    .format {
      display: block;

      font-size: 7.03px;
      font-weight: 400;
      line-height: 9.57px;
      text-align: center;

      padding: 2px 0;
      min-width: 45.37px;
      border-radius: 300px;
      background-color: white;
      border: 1px solid #00825d;
    }
  }

  &:hover {
    box-shadow: 0px 4.69px 4.69px 0px #00000033;

    .formats {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 768px) {
    box-shadow:
      0px 0px 1.17px 0px #919e9b29,
      0px 2.34px 7.03px 0px #4c57540f,
      0px 4.1px 9.96px 0px #4c575414;

    &,
    .cover,
    .formats {
      border-radius: 8px;
    }

    .cover {
      width: 186px;
      height: 280px;
    }

    .formats .format {
      font-size: 14px;
      line-height: 1.1;

      min-width: 100px;
    }
  }
}
