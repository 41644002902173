.container {
  position: relative;

  &::before {
    content: '';
    position: absolute;

    right: 50%;
    bottom: 50%;
    z-index: -1;
    transform: translate(50%, 50%);

    width: 100%;
    height: 100%;
    max-width: 86%;
    max-height: 175px;
    border-radius: 32px;
    background: #e0fff6;
  }

  .text {
    color: #fff;

    b {
      font-weight: bold;
      color: #0bda68;
    }
  }

  @media only screen and (min-width: 768px) {
    &::before {
      max-width: 640px;
      max-height: 252px;
    }
  }
}

.bottomText {
  a {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
