.number {
  transition: color 0.3s;

  &:global(.skoob) {
    color: #28302e;
  }

  &:global(.skeelo) {
    color: #fff;
  }

  &.finished {
    &:global(.skoob) {
      color: #0372c6;
    }

    &:global(.skeelo) {
      color: #0bda68;
    }
  }
}
