.section {
  position: relative;

  @media only screen and (min-width: 1024px) {
    &::before {
      content: '';

      z-index: 20;
      position: absolute;

      display: block;
      background-color: #00543c;

      bottom: 96px;

      width: 100%;
      height: 257px;
      max-width: 44%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
    }
  }

  @media only screen and (max-width: 767px) {
    .illustration::before {
      bottom: 10%;
    }
  }

  @media only screen and (max-width: 1023px) {
    .illustration {
      position: relative;

      &::before {
        content: '';

        bottom: 15%;
        left: -25px;
        z-index: 20;
        position: absolute;

        height: 118px;
        display: block;
        border-radius: 52px;
        width: calc(100% + 50px);
        background-color: #00543c;
      }

      > div {
        z-index: 30;
        position: relative;
      }
    }
  }
}
