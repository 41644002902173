.item {
  box-shadow:
    0px 10.5px 25.5px 0px rgba(76, 87, 84, 0.08),
    0px 6px 18px 0px rgba(76, 87, 84, 0.06),
    0px 0px 3px 0px rgba(145, 158, 155, 0.16);

  &.grayScale {
    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);

      transition: filter 0.3s;
    }

    &.removeGrayScaleOnHover {
      &:hover img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 1;
        filter: alpha(opacity=100);
      }

      img {
        filter: alpha(opacity=60);
      }
    }
  }
}
